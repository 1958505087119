<template>
    <tr>
        <td>{{indice}}º</td>
        <td>
            <div v-if="modoEdicao">
                <input v-model="descritivo" @keydown.esc="cancelar" @keydown.enter="salvar">
                <div class="mt-2"><small>Para cancelar a edição, tecle <b>ESC</b> com o foco no campo.</small></div>
            </div>
            <div v-if="!modoEdicao">
                {{descritivo}}
            </div>

            <div v-if="erro" class="alert alert-danger mt-2" role="alert">
                {{msgErro}}
            </div>

        </td>
        <td>
            <div style="float: right;">
                <button v-if="!modoEdicao" @click="editar" type="button" class="btn btn-primary btn-sm mx-2 largura-botao">
                    Editar
                </button>

                <button v-if="modoEdicao" @click="salvar" type="button" class="btn btn-success btn-sm mx-2 largura-botao">
                    Salvar
                </button>

                <button v-if="ativado" @click="desativar" type="button" class="btn btn-dark btn-sm mx-2 largura-botao">
                    Desativar
                </button>

                <button v-if="!ativado" @click="ativar" type="button" class="btn btn-warning btn-sm mx-2 largura-botao">
                    Ativar
                </button>

                <button type="button" @click="deletar" class="btn btn-danger btn-sm mx-2 largura-botao">
                    Deletar
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
import axiosApi from '@/axios'
import store from '@/store'
export default {
    name: 'ItemCategoria',

    data(){
        return{
            modoEdicao: false,
            descritivo: '',
            ativado: false,
            erro: false,
            msgErro: '',
            antigo: ''
        }
    },

    watch:{
        erro(value){
            if (!value){
                this.msgErro = ''
            }
        }
    },

    methods:{
        editar(){
            this.erro = false
            this.modoEdicao = true
        },

        cancelar(){
            this.erro = false
            this.modoEdicao = false
        },

        async desativar(){
            try {
                this.erro = false
                store.commit('setLoading', true)
                let dados = {'ativo': false}   
                await axiosApi.put(`/admin/categoria/${this.id}`, dados)
                this.ativado = false
            } catch(error){
                this.erro = true
                this.msgErro = error.response.data.erro
            } finally{
                store.commit('setLoading', false)
            }
        },

        async ativar(){
            try {
                this.erro = false
                store.commit('setLoading', true)
                let dados = {'ativo': true}   
                await axiosApi.put(`/admin/categoria/${this.id}`, dados)
                this.ativado = true
            } catch(error){
                this.erro = true
                this.msgErro = error.response.data.erro
            } finally{
                store.commit('setLoading', false)
            }
        },

        async deletar(){
            try {
                this.erro = false
                store.commit('setLoading', true)  
                await axiosApi.delete(`/admin/categoria/${this.id}`)
                this.ativado = true
                this.$emit('on-deletar')
            } catch(error){
                this.erro = true
                this.msgErro = error.response.data.erro
            } finally{
                store.commit('setLoading', false)
            }
        },

        async salvar(){
            try {
                this.erro = false
                store.commit('setLoading', true)
                let dados = {'descricao': this.descritivo.toUpperCase()}   
                await axiosApi.put(`/admin/categoria/${this.id}`, dados)
                this.descritivo = this.descritivo.toUpperCase()
                this.modoEdicao = false
            } catch(error){
                this.erro = true
                this.msgErro = error.response.data.erro
                this.descritivo = this.antigo
            } finally{
                store.commit('setLoading', false)
            }
        }
    },

    props:{
        indice: Number,
        id: Number,
        descricao: String,
        ativo: Boolean
    },

    mounted(){
        this.descritivo = this.descricao
        this.ativado = this.ativo
        this.antigo = this.descritivo
    }

}
</script>

<style>
    .largura-botao{
        min-width: 100px;
    }
</style>