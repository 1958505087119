<template>
<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">
                            Adicionar Categoria
                        </h4>
                        <form class="forms-sample">
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" @keydown.enter.prevent="adicionarCategoria" class="form-control" v-model="nome" placeholder="Nome da categoria" aria-label="Nome da categoria" />                                    
                                    <div class="input-group-append">
                                        <button @click="adicionarCategoria" class="btn btn-sm btn-primary" type="button">
                                            Adicionar
                                        </button>
                                    </div>
                                </div>

                                <div v-if="erro" class="invalid-feedback">
                                    {{msgErro}}
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h4 class="card-title">Minhas Categorias</h4>
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <tbody>
                                    <item-categoria @on-deletar="deletar" v-for="(item, index) in categorias" 
                                        :key="item.id" 
                                        :indice="++index" 
                                        :id="item.id" 
                                        :descricao="item.descricao" 
                                        :ativo="item.ativo"/>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import axiosApi from '@/axios'
import ItemCategoria from '@/views/ItemCategoria.vue'
import store from '@/store'
export default {
    name: 'Categorias',
    components:{
        ItemCategoria
    },

    watch:{
        nome(){
            this.erro = false
            this.msgErro = ''
        }
    },

    data(){
        return{
            categorias: [],
            nome: '',
            erro: false,
            mgErro: ''
        }
    },

    methods:{
        async adicionarCategoria(){
            try {
                store.commit('setLoading', true)
                let data = {'descricao': this.nome.toUpperCase(), 'ativo': true}
                await axiosApi.post('/admin/categoria', data)
                this.nome = ''
                await this.listarCategorias()
            }catch(error){
                this.erro = true
                this.msgErro = error.response.data.erro
            }finally {
                store.commit('setLoading', false)
            }
        },

        async listarCategorias() {
            try {
                store.commit('setLoading', true)
                const response = await axiosApi.get('/admin/categoria')
                this.categorias = response.data.itens
            }finally {
                store.commit('setLoading', false)
            }
        },

        async deletar(){
            await this.listarCategorias()
        }
    },

    async mounted(){
        await this.listarCategorias()
    }
};
</script>

<style>
    .invalid-feedback {
        display: block;
        font-size: 12px;
    }
</style>